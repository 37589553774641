import { API } from 'aws-amplify';


const dataProvider = {
    getList: async (resource, params) => {
        let response = await API.get(`${resource}Api`, `/${resource}`)
        // add `denyUrl` property
        const data = response.map(row => {
            const maileeApiEndpoint = API.Credentials._config.aws_cloud_logic_custom.find(a => a.name==="maileeApi").endpoint
            const denyUrl = `${maileeApiEndpoint}/mailee/deny/${row.id}`
            return { ...row, denyUrl }
        })
        return { data, total: data.length }
    },
    getOne: async (resource, params) => {
        const { id } = params
        const response = await API.get(`${resource}Api`, `/${resource}/${id}`)
        return { data: response }
    },
    create: async (resource, params) => {
        const { data } = params
        if (data.deny===undefined) {
            data.deny = false
        }
        const response = await API.post(`${resource}Api`, `/${resource}`, { body: data })
        return response
    },
    update: async (resource, params) => {
        const { data } = params  
        const response = await API.put(`${resource}Api`, `/${resource}`, { body: data })
        return response
    },
    delete: async (resource, params) => {
        const { id } = params
        const response = await API.del(`${resource}Api`, `/${resource}/${id}`)
        return response
    },
    deleteMany: async (resource, params) => {
        const { ids } = params
        ids.forEach(async id => await dataProvider.delete(resource, { id }))
    }
}


export default dataProvider;
