import React from 'react'
import { Admin, Resource } from "react-admin"
// import authProvider from "./authProvider"
import dataProvider from "./dataProvider"
import authProvider from "./authProvider"
import { MaileeList, MaileeEdit, MaileeCreate } from "./resource"


export default function() {
    return (
        <Admin dataProvider={dataProvider} authProvider={authProvider}>
            <Resource name="mailee" list={MaileeList} edit={MaileeEdit} create={MaileeCreate} />
        </Admin>
    )
}
