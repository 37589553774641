import React, { cloneElement } from 'react'
import {
    List,
    Create,
    Datagrid,
    SimpleForm,
    TextField,
    BooleanField,
    TextInput,
    BooleanInput,
    EditButton,
    useEditController,
    useRedirect,
    useRefresh,
    useNotify,
} from 'react-admin';
import dataProvider from "../dataProvider"
import { FaCopy } from "react-icons/fa"


 /*******
 * List *
 *******/

 const DenyUrlField = ({record, source}) => {
    const notify = useNotify()
    return <FaCopy
    size={30}
    color="deepskyblue"
    onClick={e => {
        navigator.clipboard.writeText(record.denyUrl)
        e.stopPropagation()
        notify(`${record.name} 님의 수신거절 URL이 복사되었습니다.`)
    }}/>
 }

export const MaileeList = props => {
    return <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <TextField source="email" />
            <BooleanField source="deny" />
            <DenyUrlField source="denyUrl" />
            <EditButton />
        </Datagrid>
    </List>
};


/**********
 * Create *
 *********/

export const MaileeCreate = props => (
    <Create title="Mailee create" {...props} > 
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="email" />
            <BooleanInput source="deny" initialValue={false} />
        </SimpleForm>
    </Create>
);


/********
 * Edit *
 *******/

const MyEdit = props => {
    const { resource, id } = props
    // the react-admin tutorial example (https://marmelab.com/react-admin/Tutorial.html) do not make any action when 'save' button is clicked. this is bug.
    // so we define 'save' callback manually
    const redirect = useRedirect()
    const refresh = useRefresh()
    const editProps = useEditController(props)
    editProps.save = async data => {
        await dataProvider.update(resource, {
            id,
            data
        })
        refresh()
        redirect(`/${resource}`)
    }

    return <>
        <h1>{props.title}</h1>
        {cloneElement(props.children, editProps)}
    </>
}

export const MaileeEdit = props => {
    return <MyEdit title="Mailee edit" {...props}> 
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="email" />
            <BooleanInput source="deny" />
        </SimpleForm>
    </MyEdit>
};