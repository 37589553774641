import moment from 'moment'
// WARNING: authentication logic is hard coded
const USERNAME = ["yoonjoo", "riaa"]
const PASSWORD = {
    yoonjoo: "yoonjoo1206",
    riaa: "riaa1206",
}
const AUTH_TOKEN_SESSION_STORAGE_KEY = "deargen_auth_token"

export default {
    login({ username, password }) {
        if (!USERNAME.includes(username)) return Promise.reject()
        if (PASSWORD[username]!==password) return Promise.reject()
        // set expire date as after some minutes from now
        const expire = new moment().add(3, "days")
        const token = JSON.stringify({ expire: expire.toISOString() })
        window.localStorage.setItem(AUTH_TOKEN_SESSION_STORAGE_KEY, token)
        return Promise.resolve()
    },
    logout: params => {
        window.localStorage.removeItem(AUTH_TOKEN_SESSION_STORAGE_KEY)
        return Promise.resolve()
    },
    checkAuth: params => {
        const token = JSON.parse(window.localStorage.getItem(AUTH_TOKEN_SESSION_STORAGE_KEY))
        if (!token) return Promise.reject()
        const expire = new moment(token.expire)
        if (expire < new moment()) {
            window.localStorage.removeItem(AUTH_TOKEN_SESSION_STORAGE_KEY)
            return Promise.reject()
        }

        // extend expiry date
        const newExpire = new moment().add(3, "days")
        const newToken = JSON.stringify({ expire: newExpire.toISOString() })
        window.localStorage.setItem(AUTH_TOKEN_SESSION_STORAGE_KEY, newToken)
        return Promise.resolve()
    },
    checkError: error => {
        return Promise.resolve()
    },
    getPermissions: params => {
        return Promise.reject()
    },
};