import React from 'react';
import DeargenMailAdmin from "./admin"

function App() {
  return (
    <DeargenMailAdmin />
  );
}

export default App;
